export const apiBaseUri = process.env.REACT_APP_LEARNER_API_BASE_URL || "";
export const serverName = process.env.REACT_APP_SERVER_NAME || "";
export const isProd = serverName === "p2e.csccloud.in";
export const cscConnectApiBaseUri =
  process.env.REACT_APP_CSC_CONNECT_API_BASE_URL || "";
export const cscConnectCallbackUri =
  process.env.REACT_APP_CSC_CONNECT_CLIENT_CALLBACK_URI || "";
export const cscConnectClientId =
  process.env.REACT_APP_CSC_CONNECT_CLIENT_ID || "";
export const p2ePortalUrl =
  "https://lpcvp2ecsc.azurefd.net/#/course/my-courses";
export const resourcesUrl =
  "https://drive.google.com/drive/folders/1vTYjNWvRTYSG5aAFg_GafWuFZsN83Tj5?usp=sharing";
export const aadhaarGatewayUrl =
  process.env.REACT_APP_AADHAAR_GATEWAY_URL || "";
export const aadhaarGatewayClientId =
  process.env.REACT_APP_AADHAAR_GATEWAY_CLIENT_ID || "";
